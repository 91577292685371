/* You can add global styles to this file, and also import other style files */
@import "colors";
@import "fonts";
@import "material";

/*********************General Style*********************/
:host ::ng-deep .timepicker-dial__control {
  background-color: transparent !important;
}
.background-primary {
  background-color: $light-corn-silk;
}

.background-secondary {
  background-color: $white;
}

.page-container {
  padding: 0 20px 70px 10px;
}

.tab-container {
  padding: 10px 15px;
}

.app-title {
  font-size: 26px;
  color: $red;
  margin-left: 8px;
  font-weight: bold;
  height: 45px;
  display: flex;
}

.main-container {
  overflow-y: auto;
  height: 100%;
}

.page-title {
  font-size: 26px;
  color: #75c5b4;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.details-section {
  margin-bottom: 10px;
}

hr {
  &.header-divider {
    margin-top: 0;
  }
}

.panel-container {
  display: flex;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.cursor {
  cursor: pointer;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.page-header {
  background-color: #00b3e6;
  color: #fff;
  align-items: center;
  font-size: 16px;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 10px;
  padding: 10px 0;
}

.search-label {
  color: gray;
  font-weight: bold;
}

.content {
  padding-top: 10px !important;
  background-color: #ffffff;
  font-weight: bold;
  width: 100%;
}

.left-panel {
  background-color: #00b3e6;
  border: 1px solid #00b3e6;
  width: 25%;
  padding: 10px 15px;
}

.right-panel {
  width: 80%;
  margin-left: 10px;
}

.right-panel-content {
  background: #fff;
  padding: 10px 15px;
}

.order-status {
  margin-bottom: 20px;
}

input[readonly] {
  background-color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.red {
  color: red;
}

.pointer {
  cursor: pointer;
  input {
    cursor: pointer;
  }
}

.pointer:hover {
  input {
    text-decoration: underline;
  }
}

.hint {
  margin: 0px 0 10px 0;
  color: gray;
  font-size: 0.7rem;
}

.export {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.non-clickable-icon {
  margin: 5px;
  cursor: auto !important;
}

.date-time {
  color: #02B3E6;
  text-decoration: underline;
  font-weight: bold;
}

/*********************General Style*********************/

/************************Button Style************************/

.button {
  font-size: 13.33px;
  font-weight: bold;
  font-family: $primary-font-family;
  border: none;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  box-shadow: none;
  height: 34px;

  &.primary {
    background-color: $primary-button-color;
    color: $primary-button-text-color;
    border: 1px solid $primary-button-color;

    &:hover {
      background-color: $hover-blue;
      color: $primary-button-text-color;
      border: 1px solid $hover-blue;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
    &:focus {
      border: 2px solid $black;
    }
  }

  &.secondary {
    background-color: $secondary-button-color;
    color: $secondary-button-text-color;
    border: 1px solid #d4d0ce;

    &:hover {
      background-color: #faf9f9;
      color: $secondary-button-text-color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
    &:focus {
      border: 2px solid $black;
    }
  }
}

button:focus {
  outline: none;
}

button[disabled] {
  cursor: default;
  color: #b5b2b0 !important;
}

/************************Button Style************************/

/************************Form Style************************/

.form-horizontal-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  .form-controls {
    &:not(:first-child) {
      padding-left: 15px;
    }

    margin-bottom: 0;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    button:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.form-vertical-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;

  .form-controls {
    min-height: 30px;
  }
}

.form-controls {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  &.info {
    min-height: unset;
    align-items: baseline;
  }

  &.comment-control {
    flex-direction: column;
    align-items: baseline;

    .form-label {
      margin-bottom: 5px;
    }
  }

  &.baseline {
    align-items: baseline;
  }

  .form-label {
    font-weight: bold;
    margin-right: 15px;
    font-size: 12.66px;
    margin-bottom: 0;
  }

  .date-control {
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .mat-datepicker-toggle {
      .mat-mdc-icon-button {
        height: 30px;
        width: 30px;
        line-height: 30px;
        padding: 0;
      }
    }
  }

  input,
  select,
  mat-select {
    display: inline;
    max-height: 30px;
    font-size: 12px;
  }

  .multiselect {
    max-height: 70px;
    height: 70px;
  }

  textarea {
    display: inline;
    max-height: 90px;
    font-size: 12px;
  }
 

  .mat-mdc-form-field {
    width: 100%;
    max-width: 300px;
  }
}

.invalid {
  border: 1px solid $red;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $red;
  }
}

/************************Form Style*********************/

/*********************Search Control Style*************/

.search-box {
  border-radius: 5px;

  .search-control-panel {
    padding: 10px 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

    .search-controls {
      .mat-mdc-form-field,
      .mat-mdc-checkbox {
        max-height: 43px;
        align-self: center;
      }
    }
  }

  .search-box-header {
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13.33px;
    font-weight: bold;
    background-color: $medium-blue;
    color: $white;
  }

  .search-actions {
    display: flex;
    width: 100%;
    align-items: center;

    .add-new {
      font-size: 20px;
      color: $red;
    }

    .actions-buttons {
      display: flex;
      flex: auto;
      justify-content: flex-end;

      button {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
}

.search-controls {
  display: flex;

  .mat-mdc-form-field,
  .mat-mdc-checkbox {
    max-height: 40px;
    align-self: center;
  }

  .mat-mdc-form-field:not(:first-child),
  .mat-mdc-checkbox {
    padding-left: 25px;
  }

  .custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/*********************Table/Grid Style*********************/

.empty-table {
  padding: 20px 10px;
  height: calc(100% - 46px);
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;

  span {
    font-weight: bold;
    text-align: center;
  }
}

.table-container > thead > tr > th {
  border: none;
}

.table-container {
  border: 1px solid #c8c8d2;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 0;
  overflow: hidden;

  &.nested-table {
    width: auto;
    margin: 10px;
  }
}

table {
  width: 100%;

  &.table-wrap {
    tr {
      background-color: $light-gray;

      &.mat-mdc-header-row {
        height: 40px;

        .mat-mdc-table-sticky {
          top: -1px !important;
        }
      }

      &.mat-mdc-footer-row,
      &.mat-mdc-row {
        height: 30px;

        &.selected {
          background-color: $light-yellow-green !important;
        }
      }

      .mat-mdc-header-cell,
      .mat-mdc-cell,
      .mat-mdc-footer-cell {
        vertical-align: middle;
        align-content: center;
        text-align: center;
        padding: 0 10px;

        &:first-of-type {
          padding-left: 15px;
        }

        &:last-of-type {
          padding-right: 15px;
        }

        &.ellipsis-col {
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;

          &:hover {
            white-space: normal;
          }
        }

        .form-controls {
          margin-bottom: 0;
        }

        .fa {
          cursor: pointer;
          font-size: 14px;
          line-height: unset;

          &.fa-bars,
          &.fa-eye {
            color: black;
          }

          &.fa-trash-alt,
          &.fa-minus-circle {
            color: $red;
          }
        }

        a {
          &.link {
            text-decoration: underline;
            font-weight: bold;
          }
        }
      }

      th {
        color: $white;
        background-color: $medium-blue !important;
        font-size: 13.33px;
        font-weight: bold;

        &.mat-mdc-header-cell {
          .mat-sort-header-arrow {
            color: $medium-black;
          }
        }
      }

      td {
        font-size: 12.66px;

        &.mat-mdc-cell {
          border-color: $grey;

          a {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }

    tr.main-row {
      background-color: $light-gray;

      &.active-row {
        background: $medium-grey !important;
      }

      &.control-row {
        height: 35px;

        .form-controls {
          justify-content: center;
          margin-bottom: 0;

          select {
            max-width: 300px;
          }
        }
      }

      &.select-row {
        cursor: pointer;
      }
    }
  }
}

.no-records {
  background-color: $light-gray;
  text-align: center;
  padding: 5px;
}

tr.detail-row,
tr.example-detail-row {
  height: 0 !important;
}

.table-row td {
  border-bottom-width: 0;
}

.table-detail-container {
  overflow: hidden;
  display: flex;
}

.mat-mdc-form-field {
  .mat-mdc-icon-button {
    color: rgba(0, 0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.fa-calendar {
  position: absolute;
  right: -27px;
  top: 12px;
}

/*********************Table/Grid Style*********************/

/********************* toggle slider **********************/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #968f8b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  left: 4px;
  width: 16px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00838a;
  .test {
    left: 7px !important;
  }
}

input[disabled] + .slider {
  background-color: #d4d0ce;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.test {
  position: absolute;
  top: 4px;
  left: 25px;
  color: white;
}

a, a:visited, a:hover, a:active {
  color: inherit !important;
}

.select-all {
  padding: 3px 13px;
}

/*Drag and Drop*/

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.spinner {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin { 
      from { 
          -ms-transform: rotate(0deg); 
      } to { 
          -ms-transform: rotate(360deg); 
      }
  }
  @-moz-keyframes spin { 
      from { 
          -moz-transform: rotate(0deg); 
      } to { 
          -moz-transform: rotate(360deg); 
      }
  }
  @-webkit-keyframes spin { 
      from { 
          -webkit-transform: rotate(0deg); 
      } to { 
          -webkit-transform: rotate(360deg); 
      }
  }
  @keyframes spin { 
      from { 
          transform: rotate(0deg); 
      } to { 
          transform: rotate(360deg); 
      }
  }
}