@import "@angular/material/theming";
// @import "@angular/material/prebuilt-themes/indigo-pink.css";

$custom-typography: mat-typography-config(
  $font-family: $primary-font-family,
);

@include angular-material-typography($custom-typography);

mat-error {
  font-size: 115% !important;
}

.row {
  align-items: baseline;
}

/*********************Material Dialog Style*********************/

.cdk-overlay-pane {
  &.confirm-dialog {
    min-height: 160px !important;

    &.confirm-cancel {
      min-width: 600px;
    }

    &.confirm-delete,
    &.confirm-save {
      min-width: 350px;
    }
  }

  &.message-dialog {
    min-height: 155px;
    min-width: 400px;
  }
}

/*********************Material Dialog Style*********************/

/*************** Material Components Styles *****************/

.mdc-menu-surface.mat-mdc-select-panel {
  padding-top: 0px !important;
}

.mat-mdc-option {
  min-height: 35px !important;
}

.mat-mdc-option[aria-disabled=true].contains-mat-select-search {
  margin-top: 0 !important;
}

.mat-sort-header-container {
  display: flex;
  justify-content: center;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0) !important;
}

::ng-deep .mat-expansion-indicator::after {
  transform: rotate(-45deg) !important;
}

.mat-mdc-header-cell {
  &.text-align-center {
    text-align: center;
  }
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__trailing {
  border-radius: 0 !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 5px !important;
  font-size: 75%;
  top: -5px !important;
}

.mat-mdc-select-value-text, .mat-mdc-floating-label, .mdc-floating-label--float-above {
  font-size: 12.66px !important;
}

.mdc-floating-label--float-above {
  top: 22px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 15px;
}

.mat-mdc-form-field-infix {
  min-height: 35px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  top: 1px;
  bottom: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0;
}

.mat-mdc-card {
  .mat-mdc-card-header {
    justify-content: center;

    .mat-mdc-card-title {
      color: #75c5b4;
      margin-bottom: 0;
      font-size: 13.33px;
      font-weight: bold;
    }
  }

  .mat-mdc-card-content {
    font-size: 12.66px;
  }

  .mat-mdc-card-actions {
    text-align: right;

    .button {
      margin-left: 12px;
    }
  }
}

.mdc-list-item__primary-text {
  font-size: 12.66px !important;
  color: black !important;
}

.mat-mdc-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-mdc-form-field {
  font-size: 12.66px !important;
  appearance: outline;
}

.mdc-text-field--disabled {
  pointer-events: all !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  top: -6px;
}

.mat-mdc-menu-panel {
  border: 1px solid $grey;
  min-height: 0 !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;

  .column-title {
    font-weight: bold;
    margin-left: 15px;
  }

  .extra-columns {
    margin-top: 10px;

    .mat-mdc-checkbox {
      height: 30px;
      line-height: 30px;
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.date-control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .mat-datepicker-toggle {
    .mat-mdc-icon-button {
      height: 30px;
      width: 30px;
      line-height: 30px;
      padding: 0;
    }
  }
}

.mat-expansion-panel-header {
  background-color: $medium-blue !important;
  height: 40px;
}

.mat-accordion {
  width: 100%;
  margin-bottom: 10px;
}

.mat-expansion-panel-header-title {
  color: $white !important;
}

::ng-deep .mat-expansion-panel-body {
  padding: 0 15px 5px !important;
}

.content .mat-mdc-form-field {
  width: 100% !important;
  margin-bottom: -10px !important;
}

.mdc-text-field {
  padding: 0px 0.75em !important;
}

label {
  margin-bottom: 0px !important;
}

/********************date picker icon css*************/

.mat-mdc-tab-labels {
  font-weight: bold;
  font-size: 13px;
  margin-left: 10px;
  border: 1px solid $grey;
  opacity: 1 !important;

  &:first-child {
    margin-left: 0;
  }
  
  .mdc-tab--active {
    background-color: $hover-blue;
  }

  .tab-actions {
    display: flex;
    justify-content: flex-end;

    .fa {
      color: $red;
      padding-left: 10px;
    }
  }
}

.mat-mdc-tab-body-wrapper {
  border: 1px solid $grey;
  border-top: none;
  border-radius: 0;
}
.mdc-dialog__container {
  padding: 0 !important;
  font-size: 12.66px;
  .mat-mdc-dialog-title {
    color: $black;
    margin-bottom: 0 !important;
    font-size: 13.33px;
    font-weight: bold;
    display: flex !important;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $medium-blue;

    .dialog-title {
      color: $white;
      font-weight: 700;
    }

    .dialog-close-button {
      color: $dark-gray;
      flex: 1;
      text-align: right;

      a {
        color: $dark-gray;
      }

      :hover {
        color: $red;
      }
    }
  }

  .mat-mdc-dialog-content {
    padding: 15px !important;
    width: 100%;
    margin: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 85vh;
    font-size: 12.66px !important;
  }

  .mat-mdc-dialog-actions {
    padding: 10px 15px;
    justify-content: center;
    margin-bottom: 0px;

    button:not(:first-child) {
      margin-left: 15px;
    }
  }
}

/******************* Radio Button ***************************/

.mdc-radio__inner-circle {
  border-color: #00b0b9 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #00b0b9 !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #00b0b9 !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 5px !important;
}

/******************* Check Box ***************************/

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  opacity: 0.03 !important;
  background-color: #00b0b9 !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: #00b0b9 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  background-color: #00b0b9 !important;
  border-color: #00b0b9;
}

/*************** Material Components Styles *****************/
