/***************Active Palette*****************/

/**
These colors are used to draw attention and indicate primary functionality.
• Feature areas
• Calls-to-action
• Link language / highlights
*/

$red: #e41f35;
$dark-red: #730505;
$yellow: #f9c916;
$hover-blue: #549ed1;
$dark-blue: #346170;

/***************Active Palette*****************/

/***************Accent Palette*****************/

/**
These colors are used for sweeps, structure reinforcment and to balance Cardinal Health red.
• Sweeps
• Touts
• Text
• Foundation elements
*/

$sky-blue: #92d3e1;
$medium-aquamarine: #75c5b4;
$medium-blue: rgb(0, 179, 230);
$light-blue: #80b8db;
$light-green: #9bc787;
$light-yellow-green: #d0e276;
$light-gray: #efefef;
$dark-gray: #948a85;
$medium-gray: #b4adaa;
$Pantone7457: #bbdde6;
$mustard: #fdb71a;

/***************Accent Palette*****************/

/***************General Purpose Colors*****************/

/**
These colors are used for typography, navigation dividers and subtle graphic elements.
• Text
• Alternating list rows
• Navigation dividers
• Foundation elements
• Backgrounds
*/

$medium-corn-silk: #fef2c5;
$light-corn-silk: #f4f2e6;
$wheat: #e9e4cd;
$dark-golden: #907736;
$floral-white: #fcfbf7;
$smoke-white: #f1f1f1;
$medium-grey: #c8c8d2;
$medium-black: #2e2e2f;
$light-black: #27251f;
$black: #000;

/***************General Purpose Colors*****************/

/***************Other Common Colors*****************/

$white: #fff;
$grey: #ccc;

/***************Other Common Colors*****************/

/******************Button Colors**********************/

$primary-button-color: $light-blue;
$primary-button-text-color: $white;

$secondary-button-color: $white;
$secondary-button-text-color: $light-black;
$secondary-button-hover-color: $smoke-white;

/******************Button Colors**********************/
